<template>
  <div class="container">
    <p>Enter a list (separated by a comma or new line) of suggested locations that may be used by your fleet.</p>
    <textarea 
      class="form-control" 
      id="floatingInputDescription"
      rows="5"
      style="border-radius: 15px 15px 0 15px; max-width: 400px;"
      v-model="depotString"
      :disabled="this.mode ==='view'"
    ></textarea>
    <button v-if="mode === 'edit'" v-on:click="updateDepots()" class="btn-powertrip" style="margin-top: 1em; margin-left: 0;">
      Update Depots
    </button>
    <button v-if="mode === 'edit'" class="btn-cancel" v-on:click="this.mode = 'view'" style="margin-top: 1em;">
      Cancel
    </button>
    <button v-else class="btn-cancel" v-on:click="this.mode = 'edit'" style="margin-top: 1em; margin-left: 0;">
      Edit Depots
    </button>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  components: { },
  name: 'DepotList',
  props: ['role'],
  data() {
    return {
      depotString: "",
      newDepots: [],
      mode: 'view',
    }
  },
  methods: {
    depotToString(depot) {
      this.depotString = ''
      if (depot.length) {
        depot.forEach(item => {
          this.depotString = this.depotString + item + '\n'
        })
      }
    },

    stringToDepotArray() {
      this.newDepots = this.depotString.split(/[\n,]/);
      for (let i = 0; i < this.newDepots.length; i++) {
        this.newDepots[i] = this.newDepots[i].trim();
        if (!this.newDepots[i]) {
          this.newDepots.splice(i, 1);
          i--
        }
      }
    },

    async updateDepots() {
      this.stringToDepotArray()
      let depotObject = {
        role: this.role,
        depots: this.newDepots
      }
      try {
        const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/depots', depotObject, {
          withCredentials: true,
        })
        if (res.status === 200) {
          this.mode = "view"
        }
      } catch (error) {
        alert("Something went wrong...")
        console.log(error);
      }
    },
    async getDepots() {
      try {
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/depots', {
          params: {
            role: this.role,
          },
          withCredentials: true,
        })
        if (res.status === 200) {
          this.depotToString(res.data.depots)
        }
      } catch(error) {
        console.log(error)
      }
    }
  },
  beforeMount() {
    this.getDepots();
  },
  mounted() {
    
  },
  watch: {
    role: async function() {
      this.mode = 'view'
      await this.getDepots()
    },
    mode: async function() {
      if (this.mode === 'view') await this.getDepots();
    },
  }
}
</script>

<style>

</style>