<template>
  <form class="container" @submit.prevent="send('done')">
    <div class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0.35em;">
      <div class="col-auto" style="width: 100%;">
        <h5 class="mb-0 d-none d-md-block">Booking Details:</h5>
      </div>
    </div>
    <div class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0;">
      <div class="col-auto form-floating">
        <input 
          class="form-control form-width" 
          id="floatingInputStart" 
          placeholder="Pick-up Date" type="text" 
          onfocus="(this.type='date')" 
          @blur="blurInput" 
          style="border-radius: 10px 0 0 0;" 
          required
          v-model="startDate"
          v-bind:max="endDate"
          :disabled="this.mode === 'view'"
        />
        <label for="floatingInputStart">Pick-up Date</label>
      </div>
      <div class="col-auto form-floating">
        <input 
          class="form-control form-width"
          id="floatingInputEnd"
          placeholder="Drop-off Date"
          type="text"
          onfocus="(this.type='date')"
          @blur="blurInput"
          style="border-radius: 0 10px 0 0;"
          required
          v-model="endDate"
          v-bind:min="startDate"
          :disabled="this.mode === 'view'"
        />
        <label for="floatingInputEnd">Drop-off Date</label>
      </div>
    </div>
    <div class="flex-form form-signin" style="margin-top: 0; margin-bottom: 0.35em;">
      <div class="col-auto form-floating">
        <input 
          class="form-control form-width" 
          id="floatingInputDepart" 
          placeholder="Depart Time" 
          type="text" 
          onfocus="(this.type='time')" 
          @blur="blurInput" 
          style="border-radius: 0 0 0 10px;" 
          required
          v-model="startTime"
          :disabled="this.mode === 'view'"
        />
        <label for="floatingInputDepart">Depart Time</label>
      </div>
      <div class="col-auto form-floating">
        <input 
          class="form-control form-width" 
          id="floatingInputReturn" 
          placeholder="Return Time" 
          type="text" 
          onfocus="(this.type='time')" 
          @blur="blurInput" 
          style="border-radius: 0 0 10px 0;" 
          required
          v-model="endTime"
          :disabled="this.mode === 'view'"
        />
        <label for="floatingInputReturn">Return Time</label>
      </div>
    </div>
    
    <div class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0;">
      <div class="col-auto form-floating">
        <input 
          class="form-control form-width" 
          id="floatingInputLocStart" 
          placeholder="Pick-up Location" 
          type="text"
          @blur="blurInput" 
          style="border-radius: 10px 0 0 10px;" 
          v-model="location.start"
          :disabled="this.mode === 'view'"
        />
        <label for="floatingInputLocStart">Pick-up Location</label>
      </div>
      <div class="col-auto form-floating">
        <input 
          class="form-control form-width" 
          id="floatingInputLocEnd" 
          placeholder="Drop-off Location" 
          type="text" 
          @blur="blurInput" 
          style="border-radius: 0 10px 10px 0;" 
          v-model="location.end"
          :disabled="this.mode === 'view'"
        />
        <label for="floatingInputLocEnd">Drop-off Location</label>
      </div>
    </div>
    <div class="flex-form form-signin" style="margin-top: 1em; margin-bottom: 0.35em;">
      <div class="col-auto" style="width: 100%;">
        <p class="mb-0 d-none d-md-block">Organiser: {{ this.organizer }}</p>
      </div>
    </div>
    <div class="flex-form form-signin" style="margin-top: 1em; margin-bottom: 0.35em;">
      <div class="col-auto" style="width: 100%;">
        <h5 class="mb-0 d-none d-md-block">Passenger Details:</h5>
      </div>
    </div>
    <div v-if="this.organizer.indexOf('<' + this.$root.email + '>') >= 0 || this.$root.adminRoles.includes(this.role)" class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0.35em;">
      <div class="col-auto" style="width: 100%;">
        <input
          class="form-check-input"
          type="checkbox"
          id="allowPassengers"
          v-model="allowPassengers"
          :disabled="this.mode === 'view'"
        />
        <label for="allowPassengers" class="form-check-label text-nowrap" style="padding-left: 15px;">Allow passengers?</label>
      </div>
    </div>
    <div v-if="allowPassengers" class="flex-form form-signin" style="margin-top: 0; margin-bottom: 0.35em;">
      <div class="col-auto form-floating" style="width: 100%; max-width: 300px;">
        <input 
          class="form-control" 
          id="floatingInputNumPass" 
          placeholder="Number of Passengers" 
          type="number" 
          @blur="blurInput" 
          style="border-radius: 10px;" 
          v-model="numberOfPassengers"
          :disabled="(this.mode ==='view' || !allowPassengers)"
          v-bind:min="1"
          v-bind:max="(this.resource.seats) ? this.resource.seats : 100"
        />
        <label for="floatingInputNumPass">Number of Passengers</label>
      </div>
    </div>
    <div v-if="allowPassengers" class="flex-form form-signin" style="margin-top: 0; margin-bottom: 0.35em;">
      <div class="col-auto form-floating" style="width: 100%; max-width: 300px;">
        <textarea 
          class="form-control" 
          id="floatingInputDescription"
          placeholder="Notes to Passengers" 
          rows="1"
          style="border-radius: 10px 10px 0 10px;"
          v-model="bookingDescription"
          :disabled="(this.mode === 'view' || !allowPassengers)"
        ></textarea>
        <label for="floatingInputDescription">Notes to Passengers</label>
      </div>
    </div>
    <div class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0.35em;">
      <div class="col-auto" style="width: 100%;">
        <div v-if="!allowPassengers && !(this.organizer.indexOf('<' + this.$root.email + '>') >= 0 || this.$root.adminRoles.includes(this.role))">No passengers allowed.</div>
        <div v-if="allowPassengers">
          <h6 class="mb-0 d-none d-md-block">Passenger List:</h6>
          <p v-for="passenger of this.passengers" :key="passenger" class="mb-0 opacity-75 d-none d-md-block" >{{ passenger.split(' &lt;')[0] }}</p>
          <p v-if="!this.passengers.length" class="mb-0 opacity-75 d-none d-md-block"><i>No passengers yet.</i></p>
        </div>
      </div>
    </div>

    <div class="mb-3 flex-form" style="margin-top:0.35em;">
      <template v-if="this.organizer.indexOf(this.$root.email) >= 0 || this.$root.adminRoles.includes(this.role)">
        <button type="button" class="btn-powertrip" v-if="this.mode === 'view'" v-on:click="this.$emit('edit')" style="width:auto; height:auto; padding: .375rem .75rem; font-size: 1rem; margin-right: 5px;">
          Edit
        </button>
        <button type="submit" class="btn-powertrip" v-if="this.mode === 'edit'" style="width:auto; height:auto; padding: .375rem .75rem; font-size: 1rem; margin-right:5px">
          {{ $t('labels.save') }}
        </button>
      </template>
      <button type="button" class="btn-powertrip" v-if="this.mode === 'view' && !(this.organizer.indexOf('<' + this.$root.email + '>') >= 0)" v-on:click="joinTrip()" style="width:auto; height:auto; padding: .375rem .75rem; font-size: 1rem;">{{ this.passenger ? "Leave Trip" : "Join Trip" }}</button>
      <button type="button" class="btn-cancel" v-on:click="this.$emit('cancel')">
        {{ $t('labels.cancel') }}
      </button>
      <button type="button" class="btn-delete" v-if="this.mode === 'edit'" v-on:click="send('delete')" style="width:auto; height:auto; padding: .375rem .75rem; font-size: 1rem; margin-left:">
        <i class="bi bi-trash"></i>
        Delete
      </button>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
export default {
  components: {},
  name: 'BookingForm',
  props: {
    booking: {
      type: Object,
      default: function () {
        return {
          startDate: new Date().toISOString().split('T')[0] + 'T16:00',
          endDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] + 'T12:00',
          summary: '',
          locationStart: '',
          locationEnd: '',
          acceptPassengers: false,
          numberOfPassengers: 0,
          passengers: [],
          service: false,
          resource: undefined,
          organizer: undefined,
          subresources: undefined,
        }
      },
    },
    mode: {
      type: String,
      required: true,
      validator: function (value) {
        return ['view', 'edit'].indexOf(value) !== -1
      },
    },
    role: {
      type: String
    }
  },
  data() {
    return {
      organizer: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      location: {
        start: '',
        end: '',
      },
      service: false,
      subresources: null,
      allowPassengers: false,
      numberOfPassengers: 0,
      passengers: [],
      bookingDescription: '',
      formBooking: this.booking,
      isNewOpened: true,
      passenger: false,
    }
  },
  methods: {
    async joinTrip() {
      if (this.passengers.length >= this.numberOfPassengers && !this.passenger) {
        alert('The maximum number of passengers has already been reached!')
        return
      }
      let newPassengers = [];
      try {
        const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/booking/join',
          {
            resource: this.resource,
            uid: this.formBooking.uid,
          },
          { withCredentials: true },
        )
        if (res.status === 200) {
          if (res.data.updatedBooking.passengers) {
            newPassengers = [...res.data.updatedBooking.passengers]
          }
        }
      } catch (error) {
        if(error.response){
          console.log(error.response.data)
        }
      }
      this.passengers = [...newPassengers]
    },
    setBooking(booking) {
      this.formBooking = {}
      Object.assign(this.formBooking, booking)
      this.formBooking.startDate = new Date(new Date(booking.startDate).getTime() - new Date().getTimezoneOffset() * 60 * 1000)
        .toISOString()
        .slice(0, -8)
      this.startDate = this.formBooking.startDate.split('T')[0];
      this.startTime = this.formBooking.startDate.split('T')[1];
      this.formBooking.endDate = new Date(new Date(booking.endDate).getTime() - new Date().getTimezoneOffset() * 60 * 1000)
        .toISOString()
        .slice(0, -8)
      this.endDate = this.formBooking.endDate.split('T')[0];
      this.endTime = this.formBooking.endDate.split('T')[1];
      this.location.start = booking.locationStart;
      this.location.end = booking.locationEnd;
      this.bookingDescription = booking.description;
      this.allowPassengers = booking.acceptPassengers;
      this.numberOfPassengers = booking.numberOfPassengers;
      this.passengers = booking.passengers;
      this.organizer = booking.organizer;
      this.resource = booking.resource;
    },
    send(emitWhat) {
      let bookingDetails = {
        uid: this.formBooking.uid,
        resource: this.formBooking.resource,
        startDate: this.startDate + 'T' + this.startTime,
        endDate: this.endDate + 'T' + this.endTime,
        summary: this.startTime + ': ' + this.formBooking.resource + (this.allowPassengers ? ', passengers allowed.' : '.'),
        description: this.bookingDescription,
        locationStart: this.location.start,
        locationEnd: this.location.end,
        acceptPassengers: this.allowPassengers,
        numberOfPassengers: this.numberOfPassengers,
        passengers: this.passengers,
        subresources: null,
        service: false,
      }
      this.$emit(emitWhat, bookingDetails)
    },
    clear() {
      this.startDate = ''
      this.endDate = ''
      this.summary = ''
      this.location = {
        start: '',
        end: ''
      }
      this.acceptPassengers = false
      this.numberOfPassengers = 0
      this.bookingDescription = ''
      this.passengers = []
      this.service = false
      this.resource = undefined
      this.subresources = undefined
    },
    blurInput(obj) {
      //change the type back to text if nothing has been inputted.
      if(!obj.target.value) obj.target.type = 'text'
    },
  },
  mounted() {},
  watch: {
    booking: function () {
      this.setBooking(this.booking)
      this.isNewOpened = true
    },
    passengers: function() {
      //Check if the current user is a passenger
      for (let i = 0; i < this.passengers.length; i++) {
        if (this.passengers[i].includes('<' + this.$root.email + '>')) {
          this.passenger = true;
          return
        }
      }
      this.passenger = false;
    }
  },
}
</script>

<style>
  .btn-cancel {
    margin-left: 5px;
		background-color: #e4e4e4;
		color: #333333;
		width: auto;
		border: 1px solid transparent;
		border-radius: 10px;
		height: auto;
		font-size: 1rem;
    padding: .375rem .75rem;
	}
  .btn-delete {
    margin-left: 5px;
		background-color: #bb2d3b;
		color: white;
		width: auto;
		border: 1px solid transparent;
		border-radius: 10px;
		height: auto;
		font-size: 1rem;
    padding: .375rem .75rem;
  }
  .btn-delete:hover {
		background-color: #b43d49;
	}
  .btn-delete:active {
		background-color: #b8202f;
	}
  .btn-delete:focus {
		box-shadow: 0 0 0 0.2rem rgba(255, 136, 136, 0.25)
	}

	.btn-cancel:hover {
		background-color: #d4d4d4;
	}
	.btn-cancel:active {
		background-color: #cccccc;
	}
	.btn-cancel:focus {
		box-shadow: 0 0 0 0.2rem rgba(160, 160, 160, 0.25)
	}
</style>