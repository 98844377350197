<template>
  <div class="container">
    <h1>{{ $t('headlines.settings') }}</h1>
    <div class="container mb-3">
      <h2>Fleet Group</h2>
      <div class="container" style="max-width: 200px; margin-left:0">
        <select class="form-select form-check-inline" id="userRoles" v-model="role" style="margin-bottom: 10px;">
          <option v-for="role of this.roles" :key="role" :disabled="this.roles.length <= 1">{{ role }}</option>
        </select>
      </div>
    </div>
    <div v-if="this.user.globalAdmin || (this.user.adminRoles.length && this.user.adminRoles.includes(this.role))">
      <div class="container mb-3">
        <h2>{{ $t('headlines.resources') }}</h2>
        <ResourceList :resources="this.resources" :role="this.role" :resourceNames="this.resourceNames"></ResourceList>
      </div>
      <div class="container mb-3">
        <h2>Depots</h2>
        <DepotList :role="this.role"></DepotList>
      </div>
      <div class="container mb-3">
        <h2>Users</h2>
        <UserList :role="this.role"></UserList>
      </div>
      <div class="container">
        <h2>{{ $t('headlines.export') }}</h2>
        <ExportBookings :role="this.role"></ExportBookings>
      </div>
    </div>
    <div v-else>
      <div v-if="isLoading">
        {{ this.isLoading }}
      </div>
      <div v-else>
        Sorry, you don't currently have admin permissions for this fleet group!
      </div>
    </div>
  </div>
    
</template>

<script>
import ResourceList from './Settings/ResourceList.vue'
import UserList from './Settings/UserList.vue'
import ExportBookings from './Settings/ExportBookings.vue'
import DepotList from './Settings/DepotList.vue'
import axios from 'axios'
export default {
  name: 'Settings',
  components: {
    ResourceList,
    UserList,
    ExportBookings,
    DepotList
  },
  props: ['resources', 'resourceNames'],
  data() {
    return {
      roles: [],
      role: '',
      user: {
        name: '',
        adminRoles: [],
      },
      isLoading: ''
    }
  },
  methods: {
    async importUser() {
      this.user = await this.$root.getUserData()
    },
    async updateKeycloakUsers() {
      try {
        await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/updateKeycloakUsers', {
          withCredentials: true,
        })
        this.importUser()
        this.isLoading = ""
      } catch (error) {
        console.log(error)
      }
    }
  },
  beforeMount() {
    this.isLoading = "Loading permissions..."
    this.updateKeycloakUsers()
    this.roles = this.$root.getRoles()
    this.role = this.roles[0]
    if (this.$root.isLoading) {
      this.$root.getUserAndResources()
    }
  }
}
</script>

<style>
</style>