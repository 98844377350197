<template>
  <form v-if="!isLoading" class="container" @submit.prevent="submission()">
    <div class="row mb-2">
      <div class="col">
        <label for="userFormFirstName" class="form-label">
          First Name
        </label>
        <input type="text" class="form-control" id="userFormFirstName" v-model="formUser.firstName" required />
      </div>
      <div class="col">
        <label for="userFormLastName" class="form-label">
          Last Name
        </label>
        <input type="text" class="form-control" id="userFormLastName" v-model="formUser.lastName" required />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <label for="userFormMail" class="form-label">
          {{ $t('labels.email') }}
        </label>
        <input type="email" class="form-control" id="userFormMail" v-model="formUser.mail" required :disabled="this.mode === 'edit'"/>
      </div>
      <div class="col" v-if="mode === 'add'">
        <label for="userFormTempPass" class="form-label">
          Temporary Password
        </label>
        <input  type="text" class="form-control" id="userFormTempPass" v-model="formUser.tempPassword" required />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <div class="form-check">
          <label for="userFormAdmin" class="form-check-label text-nowrap"> {{ $t('labels.admin') }}</label>
          <input class="form-check-input" type="checkbox" id="userFormAdmin" role="switch" v-model="formUser.isAdmin" />
        </div>
      </div>
    </div>

    <div class="mb-2">
      <button type="submit" class="btn-powertrip" v-if="this.mode === 'add'">
        {{ $t('labels.addUser') }}
      </button>
      <button type="submit" class="btn-powertrip" v-if="this.mode === 'edit'">
        {{ $t('labels.save') }}
      </button>
      <button type="button" class="btn-cancel" v-on:click="this.$emit('cancel')">
        {{ $t('labels.cancel') }}
      </button>
    </div>
  </form>
  <div v-else>
    {{ this.isLoading }}
  </div>
</template>

<script>
export default {
  components: {},
  name: 'UserForm',
  props: {
    user: {
      type: Object,
      default: function () {
        return {
          firstName: '',
          lastName: '',
          isAdmin: false,
          tempPassword: '',
          mail: '',
        }
      },
    },
    mode: {
      type: String,
      required: true,
      validator: function (value) {
        return ['add', 'edit'].indexOf(value) !== -1
      },
    },
  },
  data() {
    return {
      formUser: this.user,
      isLoading: "",
    }
  },
  methods: {
    submission() {
      this.isLoading = "Processing user changes..."
      this.mode === 'add' ? this.$emit('add', this.formUser) : this.$emit('edit', this.formUser)
    },
    clear() {
      this.formUser = {
        firstName: '',
        lastName: '',
        isAdmin: false,
        tempPassword: '',
        mail: '',
      }
    },
  },
  beforeMount() {},
  watch: {
    user: function () {
      this.formUser = this.user
    },
  },
}
</script>

<style>
</style>