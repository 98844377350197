<template>
<!-- TODO: CHANGE THIS FORM TO BE ONLY VIEWABLE -->
  <form class="container">
    <div class="mb-2">
      <div class="row">
        <div class="col-sm">
          <label for="resourceFormMake" class="form-label">
            {{ $t('labels.make') }}
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            id="resourceFormMake"
            v-model="formResource.make"
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormModel" class="form-label">
            {{ $t('labels.model') }}
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            id="resourceFormModel"
            v-model="formResource.model"
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormYear" class="form-label">
            {{ $t('labels.year') }}
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            id="resourceFormYear"
            v-model="formResource.year"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label for="resourceFormLicense" class="form-label">
            {{ $t('labels.licensePlate') }}
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            id="resourceFormLicense"
            v-model="formResource.licensePlate"
            required
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormOdometer" class="form-label">
            {{ $t('labels.odometer') }}
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            id="resourceFormOdometer"
            v-model="formResource.odometer"
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormCharge" class="form-label">
            {{ $t('labels.charge') }}
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            id="resourceFormCharge"
            v-model="formResource.charge"
          />
        </div>
      </div>
      
      <div class="row">
        <div class="col-sm">
          <label for="resourceFormGroup" class="form-label">
            {{ $t('labels.group') }}
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            id="resourceFormCharge"
            v-model="formResource.group[0]"
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormFuel" class="form-label">
            {{ $t('labels.fuel') }}
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            id="resourceFormFuel"
            v-model="formResource.fuel"
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormSeats" class="form-label">
            {{ $t('labels.seats') }}
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            id="resourceFormSeats"
            v-model="formResource.seats"
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormGeotabID" class="form-label">
            {{ $t('labels.geotabID') }}
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            id="resourceFormGeotabID"
            v-model="formResource.geotabID"
          />
        </div>
        <div class="col-auto">
          <label for="resourceFormColor" class="form-label">
            {{ $t('labels.color') }}
          </label>
          <input
            disabled
            style="min-height: 27px"
            type="color"
            class="form-control"
            id="resourceFormColor"
            v-model="formResource.color"
            @change="formResource.color = correctColorLuminance(formResource.color)"
          />
        </div>
      </div>
    </div>
    <div class="mb-2">
      <div class="row">
        <div class="col-sm">
          <label for="resourceFormDes" class="form-label">
            {{ $t('labels.description') }}
          </label>
          <textarea disabled class="form-control" id="resourceFormDes" rows="3" v-model="formResource.description"></textarea>
        </div>
        <div class="col-sm">
          <div class = "row">
            <label v-if="this.resource.history !== ''" for="resourceFormHistory" class="form-label">Last Update: {{ this.resource.history }}</label>
          </div>
        </div>
      </div>
    </div>
    
    <div class="mb-2">
      <button type="button" class="btn-cancel" v-on:click="this.$emit('cancel')">
        {{ $t('labels.cancel') }}
      </button>
    </div>
  </form>
</template>

<script>
export default {
  components: {},
  name: 'ResourceViewForm',
  props: {
    resource: {
      type: Object,
      default: function () {
        return {
          make: '',
          model: '',
          year: '',
          name: '',
          odometer: '',
          charge: '',
          group: [],
          fuel: '',
          seats: '',
          geotabID: '',
          geotabName: '',
          licensePlate: '',
          permissionLevel: '',
          description: '',
          history: '',
          chargeHistory: '',
          img: undefined,
          color: undefined,
        }
      },
    },
    mode: {
      type: String,
      required: true,
      validator: function (value) {
        return ['add', 'edit', 'view'].indexOf(value) !== -1
      },
    },
  },
  data() {
    return {
      formResource: this.resource,
      subresourceAdd: '',
      groups: [],
    }
  },
  methods: {
    clear() {
      this.formResource = {
        make: '',
        model: '',
        year: '',
        name: '',
        odometer: '',
        charge: '',
        group: [],
        fuel: '',
        seats: '',
        geotabID: '',
        geotabName: '',
        licensePlate: '',
        permissionLevel: '',
        description: '',
        history: '',
        chargeHistory: '',
        img: undefined,
        color: this.correctColorLuminance(this.generateRandomColorHex()),
      }
    },
  },
  watch: {
    resource: function () {
      this.formResource = this.resource
    },
  },
}
</script>

<style>
</style>