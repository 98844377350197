<template>
  <div class="container">
      <powertrip-optimiser :sso=sso >Loading....</powertrip-optimiser>
      <component :is="'script'" type="application/javascript" src='https://optimiser.powertrip.earth/js/app.js'></component>
  </div>
</template>

<script>


export default {
  name: 'Optimiser',
  beforeMount(){
    if (this.$root.isLoading) {
      this.$root.getUserAndResources()
    }
  },
  data(){
    return {
      sso: process.env.VUE_APP_KEYCLOAK_REALM,
    };
  },
}

</script>

<style></style>