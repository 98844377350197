<template>
  <div>
    <Calendar></Calendar>
  </div>
</template>

<script>
import Calendar from './Calendar2.vue'
export default {
  props: ['resources'],
  components: {
    Calendar,
  },
  beforeMount() {
    if (this.$root.isLoading) {
      this.$root.getUserAndResources()
    }
  },
}
</script>

<style>
</style>