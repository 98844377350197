<template>
	<!-- Booking Modal -->
    <div class="modal fade" id="bookingInfoModal" tabindex="-1" aria-labelledby="bookingInfoModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 v-if="booked.startDate" class="modal-title" id="bookingInfoModalLabel">
						{{ $t('comp.booking.bookingSuccess.heading') }}
					</h5>
					<h5 v-else class="modal-title" id="bookingInfoModalLabel">
						{{ $t('comp.booking.bookingFailure.heading') }}
					</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div v-if="booked.startDate" class="modal-body">
					{{
					$t('comp.booking.bookingSuccess.text', {
						resources: booked.resourceNames.join(', '),
						startDate: new Date(booked.startDate).toLocaleString(),
						endDate: new Date(booked.endDate).toLocaleString(),
					})
					}}
				</div>
				<div v-else class="modal-body">
					{{ $t('comp.booking.bookingFailure.text') }}
				</div>
			</div>
		</div>
    </div>
	<!-- Calendar Booking Modal -->
	<div class="modal fade" id="bookingModal" tabindex="-1" aria-labelledby="bookingModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" style="width: 400px;">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="bookingModalLabel">
						{{ (selectedBooking.resource && selectedBooking.organizer) ? getResourceDisplayName().concat(' - ').concat(selectedBooking.organizer.split(' &lt;')[0]) : "Viewing Booking..." }}
					</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<BookingForm
						:booking="this.selectedBooking"
						:mode="this.mode"
						:role="this.role"
						v-on:cancel="this.infoModal.hide()"
						v-on:edit="this.mode='edit'"
						v-on:done="editBooking"
						v-on:delete="deleteBooking"
					></BookingForm>
				</div>
			</div>
		</div>
	</div>
	<!-- Vehicle Filter Modal -->
	<div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" style="width: 400px;">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="filterModalLabel">
						Filters
					</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<FilterForm
						:resources="this.resources2"
						v-on:cancel="this.filterModal.hide()"
						v-on:clear="this.filters = { numberOfFilters: 0 }"
						v-on:filter="filterItems"
					></FilterForm>
				</div>
			</div>
		</div>
	</div>
	<div style="display: flex; align-items: flex-start;">
		<div class="booking-container mb-3" style="margin-left:1rem; ">
			<form @submit.prevent="reserve()">
				<div class="row justify-content-center">
					<div class="col-auto" style="margin: 0.25em;">
						<div v-if="this.roles.length > 1" class="flex-form form-signin" style="margin-bottom: 0.35em;">
							<div class="col-auto form-floating" style="width: 100%; max-width:300px;">
								<select class="form-select" id="floatingUserRoles" v-model="role" style="border-radius: 10px;">
									<option v-for="role of this.roles" :key="role">{{ role }}</option>
								</select>
								<label for="floatingUserRoles">Fleet</label>
							</div>
						</div>
						<div v-else class="flex-form form-signin" style="margin-bottom: 0.35em;">
							<div v-if="this.roles.length" class="col-auto form-floating" style="width: 100%; max-width:300px;">
								<select class="form-select" id="floatingUserRoles" v-model="role" style="border-radius: 10px;" disabled>
									<option v-for="role of this.roles" :key="role">{{ role }}</option>
								</select>
								<label for="floatingUserRoles">Fleet</label>
							</div>
							<div v-else class="flex-form" style="text-align: center;">
								<i style="width:100%">You are not enrolled in any fleets.</i>
							</div>
						</div>
						<div class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0;">
							<div class="col-auto form-floating">
								<input 
									class="form-control form-width" 
									id="floatingInputStart" 
									placeholder="Pick-up Date" type="text"
									onfocus="(this.type='date')"
									@blur="blurInput"
									style="border-radius: 10px 0 0 0;" 
									required
									v-model="startDate"
									v-bind:max="endDate"
									:disabled="!this.roles.length"
								/>
								<label for="floatingInputStart">Pick-up Date</label>
							</div>
							<div class="col-auto form-floating">
								<input 
									class="form-control form-width" 
									id="floatingInputEnd" 
									placeholder="Drop-off Date" 
									type="text" 
									onfocus="(this.type='date')" 
									@blur="blurInput" 
									style="border-radius: 0 10px 0 0;" 
									required
									v-model="endDate"
									v-bind:min="startDate"
									:disabled="!this.roles.length"
								/>
								<label for="floatingInputEnd">Drop-off Date</label>
							</div>
						</div>
						<div class="flex-form form-signin" style="margin-top: 0; margin-bottom: 0.35em;">
							<div class="col-auto form-floating">
								<input 
									class="form-control form-width" 
									id="floatingInputDepart" 
									placeholder="Depart Time" 
									type="text" 
									onfocus="(this.type='time')" 
									@blur="blurInput" 
									style="border-radius: 0 0 0 10px;" 
									required
									v-model="startTime"
									:disabled="!this.roles.length"
								/>
								<label for="floatingInputDepart">Depart Time</label>
							</div>
							<div class="col-auto form-floating">
								<input 
									class="form-control form-width" 
									id="floatingInputReturn" 
									placeholder="Return Time" 
									type="text" 
									onfocus="(this.type='time')" 
									@blur="blurInput" 
									style="border-radius: 0 0 10px 0;" 
									required
									v-bind:min="compareTimes()"
									v-model="endTime"
									:disabled="!this.roles.length"
								/>
								<label for="floatingInputReturn">Return Time</label>
							</div>
						</div>
						
						<div class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0;">
							<div class="col-auto form-floating">
								<input 
									class="form-control form-width" 
									id="floatingInputLocStart" 
									placeholder="Pick-up Location" 
									type="text"
									@blur="blurInput" 
									style="border-radius: 10px 0 0 10px;" 
									v-model="location.start"
									:disabled="!this.roles.length"
									list="depots"
								/>
								<label for="floatingInputLocStart">Pick-up Location</label>
								<datalist id="depots">
									<option v-for="depot of this.depots" :key="depot">{{depot}}</option>
								</datalist>
							</div>
							<div class="col-auto form-floating">
								<input 
									class="form-control form-width" 
									id="floatingInputLocEnd" 
									placeholder="Drop-off Location" 
									type="text" 
									@blur="blurInput" 
									style="border-radius: 0 10px 10px 0;" 
									v-model="location.end"
									:disabled="!this.roles.length"
									list="depots"
								/>
								<label for="floatingInputLocEnd">Drop-off Location</label>
							</div>
						</div>
						
						<div class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0.35em;">
							<div class="col-auto" style="width: 100%;">
								<input
									class="form-check-input"
									type="checkbox"
									id="allowPassengers"
									v-model="allowPassengers"
									:disabled="!this.roles.length"
								/>
								<label for="allowPassengers" class="form-check-label text-nowrap" style="padding-left: 15px;">Allow passengers?</label>
							</div>
						</div>

						<div v-if="allowPassengers" class="flex-form form-signin" style="margin-top: 0; margin-bottom: 0.35em;">
							<div class="col-auto form-floating" style="width: 100%; max-width: 300px;">
								<input 
									class="form-control" 
									id="floatingInputNumPass" 
									placeholder="Number of Passengers" 
									type="number" 
									@blur="blurInput" 
									style="border-radius: 10px;" 
									v-model="numberPass"
									:disabled="!allowPassengers"
									v-bind:min="1"
									v-bind:max="maxSeats"
								/>
								<label for="floatingInputNumPass">Number of Passengers</label>
							</div>
						</div>

						<div v-if="allowPassengers" class="flex-form form-signin" style="margin-top: 0; margin-bottom: 0.35em;">
							<div class="col-auto form-floating" style="width: 100%; max-width: 300px;">
								<textarea 
									class="form-control" 
									id="floatingInputDescription"
									placeholder="Notes to Passengers" 
									rows="1"
									style="border-radius: 10px 10px 0 10px;"
									v-model="bookingDescription"
									:disabled="!allowPassengers"
								></textarea>
								<label for="floatingInputDescription">Notes to Passengers</label>
							</div>
						</div>

						<div class="flex-form" style="margin-top: 0.35em;">
							<button class="btn-powertrip btn-powertrip-big" type="submit">Reserve</button>
						</div>

						<!-- <div v-if="this.pages.length > 0" class="flex-form">
							<div style="float:right" v-on:click="geotabLogin">Update Vehicles</div>
						</div> -->
						<div v-if="this.pages.length > 0" class="flex-form">
							<div style="width:18%; margin-left:10%; padding-left: 15%; padding-right: 5%;" v-on:click="changePageNumber('down')">
								<i class="dropArrow arrowLeft" v-bind:class="(this.pageNumber === 1) ? 'inactiveArrow' : ''"></i>
							</div>
							<div style="width:40%; text-align:center;">
								page {{ this.pageNumber.toString() }} of {{ this.pages.length.toString() }}
							</div>
							<div style="width:8%; padding-right: 15%; padding-left:5%;" v-on:click="changePageNumber('up')">
								<i class="dropArrow arrowRight" v-bind:class="(this.pageNumber === this.pages.length) ? 'inactiveArrow' : ''"></i>
							</div>
							<div style="width:10%; padding-left:5%; display:block;">
								<i class="bi bi-filter-right" style="float:right" v-on:click="filterMenu"></i>
								<p v-if="filters.numberOfFilters" style="font-size: xx-small;position: relative;bottom: 25px;left: 14px;color: red;margin:0;height:24px;">({{ filters.numberOfFilters }})</p>
							</div>					
						</div>
						<div v-else class="flex-form" style="text-align: center;">
							<i style="width:100%; font-size: 20px;">No vehicles to display.</i>
							<div style="width:10%; padding-left:5%; display:block;">
								<i class="bi bi-filter-right" style="float:right" v-on:click="filterMenu"></i>
								<p v-if="filters.numberOfFilters" style="font-size: xx-small;position: relative;bottom: 25px;left: 14px;color: red;margin:0;height:24px;">({{ filters.numberOfFilters }})</p>
							</div>
						</div>
						

						<template v-for="resource of this.pages[this.pageNumber - 1]" :key="resource.name">
							<template v-if="resource.group.includes(this.role)">
								<div 
									class="flex-form align-items-center" 
									v-on:click="vehicleListDropdown(resource)" 
									style="margin: 0.75em 1.25em; padding: 5px 10px;" 
									v-bind:style="(resource.detailsVisible) ? 'margin-bottom: 0;' : 'margin-bottom: 0.75em;'"
									v-bind:class="[
										(resource.selected) ? ((resource.detailsVisible) ? 'selectedVisibleVehicle' : 'selectedVehicle' ) : '',
										(resource.unavailable) ? ((resource.detailsVisible) ? 'unavailableVisibleResource' : 'unavailableResource') : ''
									]"
								>
									<label class="radiocontainer">
										<input type="radio" :value="resource.name" v-model="selectedResource"/>
										<span class="checkmark"></span>
									</label>
									<div class="flex-form align-items-center" style="margin: 0; width: 165px; cursor: pointer;">
										<div class="vehicle-list-text" style="width: 165px;">
											<h6 class="mb-0 textOverflowHandler">{{ resource.year }} {{ resource.make }} {{ resource.model }}</h6>
											<p v-if="!resource.unavailable" class="mb-0 opacity-75 d-none d-md-block">{{ resource.fuel }}</p>
											<p v-else class="mb-0 opacity-75 d-none d-md-block"><i>(Unavailable)</i></p>
										</div>
										
									</div>
									<div v-if="resource.charge" class="flex-form align-items-center" style="margin: 0; width: 65px; cursor: pointer; padding-left: 10px; text-align: right;">
										<div class="vehicle-list-text" style="width: 100%;">
											<p class="mb-0 opacity-75 d-none d-md-block">{{ resource.charge }}%</p>
											<p class="mb-0 opacity-75 d-none d-md-block" style="font-size:xx-small">{{ getTimeSince(resource.chargeHistory) }}</p>
										</div>
										<i class="dropArrow" v-bind:class="!resource.detailsVisible ? 'arrowDown' : 'arrowUp' " style="margin-left: 10px;"></i>
									</div>
								</div>
								<div 
									class="flex-form align-items-center" 
									v-on:click="vehicleListDropdown(resource)" 
									v-if="resource.detailsVisible" 
									style="padding: 5px 10px; margin-top: 0; cursor: pointer; border-radius: 0 0 10x 10px;" 
									v-bind:style="[
										(resource.selected) ? 'background-color:#b6e3ff;' : '',
										(resource.unavailable) ? 'background-color:rgb(255, 180, 180);' : ''
									]"
								>
									<div class="vehicle-list-text">
										<p class="mb-0 opacity-75 d-none d-md-block">License Plate: {{ resource.licensePlate }}</p>
										<p class="mb-0 opacity-75 d-none d-md-block">Odometer: {{ resource.odometer }} km</p>
										<p v-if="resource.seats" class="mb-0 opacity-75 d-none d-md-block">Seats: {{ resource.seats }}</p>
									</div>
								</div>
							</template>
						</template>
					</div>
				</div>
			</form>
		</div>
		<div class="booking-container mb-3" style="margin-left:1rem; margin-right:1rem; max-width:1000px;">
			<div style="margin: 1.25em;">
				<FullCalendar id="fullCalendar" ref="fullCalendar" :options="calendarOptions" />
			</div>
		</div>
	</div>
</template>

<script>
	import '@fullcalendar/core/vdom'
	import { Modal } from 'bootstrap'
	import BookingForm from './Forms/BookingForm.vue'
	import FilterForm from './Forms/FilterForm.vue'
	import FullCalendar from '@fullcalendar/vue3'
	import dayGridPlugin from '@fullcalendar/daygrid'
	import interactionPlugin from '@fullcalendar/interaction'
	import iCalendarPlugin from '../libs/icalendar/main'
	import allLocales from '@fullcalendar/core/locales-all'
	import axios from 'axios'
	import jp from 'jsonpath'
	export default {
		name: 'Calendar',
		components: {FullCalendar, BookingForm, FilterForm},
		data() {
			return {
				calendarOptions: {
					plugins: [dayGridPlugin, interactionPlugin, iCalendarPlugin],
					editable: this.editable,
					selectable: true,
					showNonCurrentDates: true,
					select: this.dateSelection,
					unselectAuto: false,
					fixedWeekCount: false,
					initialView: 'dayGridMonth',
					locales: allLocales,
					locale: process.env.VUE_APP_I18N_LOCALE,
					events: {},
					headerToolbar: {
						left: 'prev,next',
						center: 'title',
						right: 'today',
					},
					displayEventTime: false,
					height: 'auto',
					aspectRatio: 2.1,
					eventDrop: this.bookingCheck,
					eventClick: this.eventClick,
					datesSet: this.changedViewDates,
				},
				dateStringOptions: { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' },
				resourceNames: [],
				resources2: [],
				roles: [],
				role: '',

				startDate: '',
				endDate: '',
				startTime: '',
				endTime: '',
				location: {
					start: '',
					end: '',
				},
				allowPassengers: false,
				numberPass: 1,
				bookingDescription: '',
				selectedResource: '',
				pageNumber: 1,
				pages: [],
				perPage: 5,

				calendarDateSelection: {start: false, end: false},
				programmaticSelection: false,

				BookingModal: undefined,
				infoModal: undefined,
				filterModal: undefined,
				selectedBooking: {},
				booked: {
					startDate: undefined,
					endDate: undefined,
					resourceNames: [],
				},
				mode: "view",
				filters: { numberOfFilters: 0 },
				maxSeats: 100,
				depots: []
			}
		},
		methods: {
			filterItems(filters) {
				this.filters = filters
				this.filterModal.hide()
				console.log(filters)
				for (let i = 0; i < this.resources2.length; i++) {
					let currentResource = this.resources2[i];
					currentResource.filteredOut = false; //reset for filter changes.
					if (filters.make) {
						if (currentResource.make !== filters.make) currentResource.filteredOut = true
					}
					if (filters.model) {
						if (currentResource.model !== filters.model) currentResource.filteredOut = true
					}
					if (filters.fuel) {
						if (currentResource.fuel !== filters.fuel) currentResource.filteredOut = true
					}
					if (filters.licPlate) {
						if (currentResource.licensePlate && currentResource.licensePlate.toLowerCase().includes(filters.licPlate.toLowerCase())) currentResource.filteredOut = true
					}
					if (filters.passengerSeats > 0) {
						if (!currentResource.seats || currentResource.seats < filters.passengerSeats) currentResource.filteredOut = true;
					}
					if (!currentResource.group.includes(this.role)) currentResource.filteredOut = true;
				}
				this.setPages()
			},
			filterMenu() {
				this.filterModal.show()
			},
			geotabLogin() {
				console.log("TODO: Add code here to trigger a geotab login to update the vehicles.");
			},
			getTimeSince(oldTime) {
				if (!oldTime) return '';
				let newTime = (new Date()).getTime();
				oldTime = (new Date(oldTime)).getTime();
				let timeSince = Math.round((newTime - oldTime) / 60000); //in mins
				let timeSinceText = '';
				if (timeSince <= 90) timeSinceText = timeSince.toString() + ' min ago';
				if (timeSince > 90 && timeSince <= 1440) timeSinceText = (Math.round(timeSince / 60)).toString() + ' h. ago';
				if (timeSinceText) return timeSinceText;
				timeSince = Math.round(timeSince / 1440); //conversion to days
				if (timeSince >= 1 && timeSince <= 26) timeSinceText = timeSince.toString() + ' d. ago';
				if (timeSince > 26 && timeSince <= 320) timeSinceText = (Math.round(timeSince / 30)).toString() + ' mo. ago';
				if (timeSinceText) return timeSinceText;
				timeSince = Math.round(timeSince / 365); //conversion to years
				if (timeSince) timeSinceText = timeSince.toString() + ' y. ago';
				return timeSinceText
			},
			async checkVehicleAvailability() {
				for (let i = 0; i < this.resources2.length; i++) {
					this.resources2[i].unavailable = false;
				}
				//set the known start dates and end dates where possible
				let tempStartDate = false;
				let tempEndDate = false;
				if (!this.startDate && !this.endDate) return;
				if (this.startDate) {
					tempStartDate = this.startDate + (this.startTime ? 'T' + this.startTime : 'T00:00') //if no start, set it to midnight to catch all
					if (!this.endDate) tempEndDate = this.oneMinChange(tempStartDate, 'more')
				}
				if (this.endDate) {
					tempEndDate = this.endDate + (this.endTime ? 'T' + this.endTime : 'T23:59') //if no end, set it to 11:59 to catch all.

					if (!tempStartDate) tempStartDate = this.oneMinChange(tempEndDate, 'less')
				}
				
				let resourceAvailability = await this.$root.getResourcesAvailability(new Date(tempStartDate), new Date(tempEndDate))
				let unavailableResourcesArray = resourceAvailability.unavailable;
				let unavailableResources = [];
				for (let i = 0; i < unavailableResourcesArray.length; i++) {
					unavailableResources.push(unavailableResourcesArray[i].resource.name)
				}
				for (let i = 0; i < this.resources2.length; i++) {
					if (unavailableResources.includes(this.resources2[i].name)) this.resources2[i].unavailable = true;
				}
			},
			oneMinChange (time, direction) {
				time = new Date(time).getTime() + ((direction === 'more') ? 60000 : -60000); //adds or subtracts a min
				return(new Date(time - new Date().getTimezoneOffset() * 60 * 1000).toISOString.slice(0, -8)) //returns time in same format as expected. accounts for timezone
			},
			async eventClick(eventClickInfo) {
				this.selectedBooking = await this.getBooking(eventClickInfo.event)
				this.mode = "view"
				this.infoModal.show()
			},
			async getBooking(booking) {
				if (!booking.id) {
					return {}
				}
				try {
					const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/booking', {
					params: {
						uid: booking.id,
						resource: booking.extendedProps.location,
					},
					withCredentials: true,
					})
					return res.data
				} catch (error) {
					if(error.response){
						console.log(error.response.data)
					}
				}
			},
			async editBooking(newBooking) {
				try {
					const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/booking/change',
					{
						old: {
							uid: this.selectedBooking.uid,
							resource: this.selectedBooking.resource,
						},
						new: {
							resource: newBooking.resource,
							startDate: newBooking.startDate,
							endDate: newBooking.endDate,
							summary: newBooking.summary,
							locationStart: newBooking.locationStart,
							locationEnd: newBooking.locationEnd,
							description: newBooking.acceptPassengers ? newBooking.description : '',
							acceptPassengers: newBooking.acceptPassengers,
							numberOfPassengers: newBooking.acceptPassengers ? newBooking.numberOfPassengers : 0,
							passengers: newBooking.passengers,
							service: newBooking.service,
							subresources: newBooking.subresources,
						},
					},
					{ withCredentials: true },
					)
					if (res.status === 200) {
						this.infoModal.hide()
						this.calendarOptions.events = this.genEventSources([this.selectedResource])
					}
				} catch (error) {
					if(error.response){
						console.log(error.response.data)
					}
					//alert(this.$t('alerts.bookingconflict'))
				}
			},
			async deleteBooking(booking) {
				if (confirm('Do you realy want to delete this booking?')) {
					try {
						const res = await axios.delete(process.env.VUE_APP_BACKEND_URL + '/api/booking', {
							params: {
								uid: booking.uid,
								resource: booking.resource,
							},
							withCredentials: true,
						})
						if (res.status === 200) {
							this.calendarOptions.events = this.genEventSources([this.selectedResource])
							this.infoModal.hide()
						}
					} catch (error) {
						alert("There was an error deleting this booking. Please try again.")
						if(error.response){
							console.log(error.response.data)
						}
					}
				}
			},
			async reserve() {
				if (!this.selectedResource) {
					if (this.resources2.length === 0) {
						alert("There are currently no vehicles available, please change your search parameters.")
					} else {
						alert("Please select a vehicle to reserve.")
					}
					
					return;
				}

				//compile bookingDetails
				let bookingDetails = {
					resources: [this.selectedResource],
					startDate: this.startDate + 'T' + this.startTime,
					endDate: this.endDate + 'T' + this.endTime,
					organizer: this.$root.getUsersDisplayName(),
					summary: this.startTime + ': ' + this.getResourceDisplayName() + (this.allowPassengers ? ', passengers allowed.' : '.'),
					locationStart: this.location.start,
					locationEnd: this.location.end,
					acceptPassengers: this.allowPassengers,
					numberOfPassengers: (this.allowPassengers) ? this.numberPass : 0,
					passengers: [],
					description: (this.allowPassengers) ? this.bookingDescription : '',
					subresources: null,
				}

				await this.bookVehicle(bookingDetails);
			},

			getResourceDisplayName(){
				const resource = this.resources2.find((resource) => resource.name === this.selectedResource);
				if (!resource) return "unnamed vehicle";
				if (resource.licensePlate && resource.licensePlate !== "...") return resource.licensePlate;
				if ((resource.make && resource.make !== "...") || (resource.model && resource.model !== "...")) return (resource.make ?? '') + (resource.make && resource.model ? ' ' : '') + (resource.model ?? '');
				if (resource.geotabName && resource.geotabName !== "...") return resource.geotabName;
				return "unnamed vehicle";
			},

			async bookVehicle(bookingDetails) {
				try {
					const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/booking', bookingDetails, {
					withCredentials: true,
					})
					if (res.status === 200) {
						console.log('#DEBUG:', 'bookVehicle res.data is', res.data);
						this.booked.startDate = res.data.startDate
						this.booked.endDate = res.data.endDate
						this.booked.resourceNames = jp.query(res.data.resources, '$..licensePlate')
					}
				} catch (error) {
					console.log(error)
					this.booked = { startDate: undefined, endDate: undefined, resourceNames: [] }
				}
				this.BookingModal.show()
				if (this.booked.startDate) this.softRefreshPage()
			},

			softRefreshPage() {
				this.calendarOptions.events = this.genEventSources([this.selectedResource])
				this.startDate = '';
				this.endDate = '';
				this.startTime = '';
				this.endTime = '';
				this.location.start = '';
				this.location.end = '';
				this.allowPassengers = false;
				// let blurFieldIDs = ["floatingInputStart", "floatingInputEnd", "floatingInputDepart", "floatingInputReturn"];
				// for (let i = 0; i < blurFieldIDs.length; i++) {
				// 	document.getElementById(blurFieldIDs[i]).blur();
				// }
				this.bookingDescription = '';
			},
			changePageNumber(direction) {
				if (direction === "down" && this.pageNumber > 1) this.pageNumber--
				if (direction === "up" && this.pageNumber < this.pages.length) this.pageNumber++
			},
			vehicleListDropdown(resource) {
				for (let i = 0; i < this.resources2.length; i++) {
					this.resources2[i].detailsVisible = (this.resources2[i].name === resource.name) ? !this.resources2[i].detailsVisible : false;
				}
				this.selectedResource = resource.name;
			},
			compareTimes() {
				if (this.startDate && this.endDate) {
					if (this.startDate === this.endDate) {
						return this.startTime
					}
				}
				return undefined
			},
			blurInput(obj) {
				//change the type back to text if nothing has been inputted.
				if(!obj.target.value) obj.target.type = 'text'
			},
			genEventSources(resourceNames) {
				if (resourceNames.length === 0) {
					return {}
				}
				const urlParts = [process.env.VUE_APP_BACKEND_URL + '/public/ical', '?token=', process.env.VUE_APP_ACCESS_TOKEN]
				for (const name of resourceNames) {
					urlParts.push('&name=' + name)
				}
				return { url: urlParts.join(''), format: 'ics' }
			},
			async getResources() {
				this.selectedResource = ''
				try {
					const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/resource', {
						withCredentials: true,
					})
					if (res.status === 200) {
						this.resources2 = [...res.data.resources]
						for (let i = 0; i < this.resources2.length; i++) {
							this.resources2['detailsVisible'] = false;
							this.resources2['unavailable'] = false;
							this.resources2['selected'] = false;
							this.resources2['filteredOut'] = false;
						}
						this.checkVehicleAvailability();
						this.resourceNames = jp.query(res.data.resources, '$..name')
						let roles = jp.query(res.data.resources, '$..group')
						for (let i = roles.length - 1; i >=0; i--) {
							if (!roles[i].includes(this.role)) {
								this.resourceNames.splice(i, 1)
								this.resources2.splice(i, 1)
							}
						}
						this.setPages();
					}
				} catch (error) {

					if(error.response){
						if (error.response.status === 401) {
							this.$router.push('login')
						} else {
							console.log(error.response.data)
						}
					}
				}
			},
			setPages() {
				this.pages = [];
				let pageArray = [];
				let tempResources = [];
				for (let i = 0; i < this.resources2.length; i++) {
					if (!this.resources2[i].filteredOut) tempResources.push(this.resources2[i])
				}
				for (let i = 0; i < tempResources.length; i++) {
					pageArray.push(tempResources[i]);

					//check if the page array needs to be pushed.
					if (pageArray.length === this.perPage || i === tempResources.length - 1) {
						this.pages.push(pageArray);
						pageArray = [];
					}
				}
			},
			dateSelection(info) {
				if (!this.roles.length) return;
				if (this.programmaticSelection) {
					this.programmaticSelection = false
					return
				}
				this.startDate = info.startStr.split('T')[0];
				this.endDate = new Date(new Date(info.endStr.split('T')[0] + "T23:59") - 24 * 60 * 60 * 1000).toISOString().split('T')[0] ; //extra stuff to remove one day (for some reason it gives a day past the selection).
			},
			async programaticallySelectDates() {
				//the date box has been entered, need to make a date selection on the calendar.
				if (!this.startDate && !this.endDate) return;

				this.programmaticSelection = true;

				if (this.startDate && this.endDate) {
					this.$refs.fullCalendar.getApi().select(this.startDate, new Date(new Date(this.endDate.split('T')[0] + 'T23:59').getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0])
				} else {
					let validDate = (this.startDate) ? this.startDate : this.endDate;
					this.$refs.fullCalendar.getApi().select(validDate);
				}
			},
			async getDepots() {
				try {
					const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/depots', {
						params: {
							role: this.role,
						},
						withCredentials: true,
					})
					if (res.status === 200) {
						return res.data.depots
					}
				} catch(error) {
					console.log(error)
				}
			}
		},
		beforeMount() {
			this.roles = this.$root.getRoles()
			this.role = this.roles[0]
			// this.getResources()
			// this.depots = this.getDepos();
			// this.getResourcesByName()
			// this.calendarOptions.events = this.genEventSources(this.resourceNames)
		},
		mounted() {
			this.BookingModal = new Modal(document.getElementById('bookingInfoModal'), {})
			this.infoModal = new Modal(document.getElementById('bookingModal'), {})
			this.filterModal = new Modal(document.getElementById('filterModal'), {})
			setInterval(() => {
				window.dispatchEvent(new Event('resize'));
			}, 300)
		},
		watch: {
			role: async function() {
				this.getResources()
				this.depots = await this.getDepots();
			},
			selectedResource: function() {
				for (let i = 0; i < this.resources2.length; i++) {
					this.resources2[i].selected = false;
					if (this.resources2[i].name === this.selectedResource) {
						this.resources2[i].selected = true;
						this.maxSeats = (this.resources2[i].seats) ? this.resources2[i].seats : 100;
					}
				}
				this.calendarOptions.events = this.genEventSources([this.selectedResource])
			},
			startDate: async function() {
				if (!this.calendarDateSelection.start) await this.programaticallySelectDates();
				this.calendarDateSelection.start = false;
				this.checkVehicleAvailability();
			},
			endDate: async function() {
				if (!this.calendarDateSelection.end) await this.programaticallySelectDates();
				this.calendarDateSelection.end = false;
				this.checkVehicleAvailability();
			},
			startTime: async function() {
				this.checkVehicleAvailability();
			},
			endTime: async function() {
				this.checkVehicleAvailability();
			},
		},
	}
	</script>
	
	<style>
	.selectedVisibleVehicle {
		background-color: #b6e3ff;
		border-radius: 15px 15px 0 0;
	}
	.selectedVehicle {
		background-color: #b6e3ff;
		border-radius: 15px;
	}
	.unavailableVisibleResource {
		background-color: rgb(255, 180, 180);
		border-radius: 15px 15px 0 0;
	}
	.unavailableResource {
		background-color:rgb(255, 180, 180);
		border-radius: 15px;
	}
	.vehicle-list-text {
		flex: 0 0 auto;
		margin-right: auto;
	}
	.form-control:focus{
		box-shadow: 0 0 0 0 !important;
	}
	.form-select:focus{
		box-shadow: 0 0 0 0 !important;
	}
	.form-check-input:focus {
		box-shadow: 0 0 0 0 !important;
	}
		/* The container */
	.radiocontainer {
		position: relative;
		padding-left: 35px;
		width: 25px;
		height: 25px;
		/* margin-bottom: 12px; */
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

		/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		border-radius: 50%;
	}
	/* On mouse-over, add a grey background color */
	.radiocontainer:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the radio button is checked, add a blue background */
	.radiocontainer input:checked ~ .checkmark {
		background-color: #2EAEFF;
		border: 2px grey;
	}

	/* Create the indicator (the dot/circle - hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the indicator (dot/circle) when checked */
	.radiocontainer input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the indicator (dot/circle) */
	.radiocontainer .checkmark:after {
		top: 9px;
		left: 9px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: white;
	}

	.radiocontainer input {
		opacity: 0;
		cursor: pointer;
	}
	.btn-powertrip {
		background-color: #2EAEFF;
		color: white;
		width: auto;
		border: 1px solid transparent;
		border-radius: 12px;
		height: auto;
		font-size: 1rem;
		padding: 0.375rem 0.75rem;
	}
	.btn-powertrip-big {
		width: 100%;
		height: calc(2px + 3.5rem);
		padding: auto;
		font-size: 1.25rem;
	}
	.btn-powertrip:hover {
		background-color: #53bdff;
	}
	.btn-powertrip:active {
		background-color: #006FF6;
	}
	.btn-powertrip:focus {
		box-shadow: 0 0 0 0.2rem rgba(46, 174, 255, 0.25)
	}
	.fc .fc-button-primary {
		background-color: #2EAEFF;
		border-color: #2EAEFF;
	}
	.fc .fc-button-primary:hover {
		background-color: #53bdff;
		border-color: #2EAEFF;
	}
	.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
		background-color: #006FF6;
		border-color: #006FF6;
	}
	.fc .fc-button-primary:disabled {
		background-color: #5595bd;
		border-color: #288dcc;
	}
	.fc .fc-button-primary:focus {
		box-shadow: 0 0 0 0.2rem rgba(46, 174, 255, 0.25)
	}
	.flex-form {
		display: flex;
		margin: 1.25em;
	}
	.form-width {
		width: 150px !important;
	}
	.booking-container {
		border-radius: 10px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		max-width: 350px;
	}
	td.fc-daygrid-day.fc-day-past {
		background-color: var(--bs-gray-200);
	}
	
	td.fc-daygrid-day.fc-day-other {
		background-color: var(--bs-gray-400);
	}
	
	a.fc-daygrid-day-number {
		color: var(--bs-dark);
		text-decoration: none;
	}
	a.fc-col-header-cell-cushion {
		color: var(--bs-dark);
		text-decoration: none;
	}
	div.fc-event-title {
		font-weight: 500;
	}
	.fc .fc-daygrid-day.fc-day-today {
		background-color: rgba(var(--bs-primary-rgb), 0.25);
		font-weight: 600;
	}
	.fc-h-event {
		border: none;
		padding-left: 5px;
		padding-right: 5px;
	}
	#fullCalendar .fc-highlight {
		background: rgba(var(--bs-success-rgb), 0.15);
	}
	.table td {
		white-space: normal;
		word-wrap: break-word;
	}
	.dropArrow {
		border: solid black;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 3px;
	}
	.inactiveArrow {
		border: solid rgb(172, 171, 171);
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 3px;
	}
	.arrowDown {
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	}
	.arrowUp {
		transform: rotate(-135deg);
		-webkit-transform: rotate(-135deg);
	}
	.arrowLeft {
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}
	.arrowRight {
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
	}
	.textOverflowHandler {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	</style>