<template>
  <div class="col-auto p-2 d-flex align-items-end">
      <i class="bi bi-info-circle"></i>
       Sorry, you are not authorised to view this page! <a href="/"> Click here </a> to return home
    </div>
</template>

<script>
export default {
  beforeMount() {
    this.$root.isLoading = false
  },
}
</script>

<style>
</style>