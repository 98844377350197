<template>
  <div v-if="!isLoading" class="container">
    <ul class="list-group mb-3" style="max-height: 400px; overflow-y: scroll">
      <template v-for="user of users" :key="user.uid">
        <li v-if="user.roles.includes(this.role) && !user.roles.includes('global_admin')"  class="list-group-item">
          <div class="row align-items-center">
            <div class="col-auto me-auto">
              <span class="fs-6">
                {{ user.firstName + ' ' + user.lastName + '&emsp;'}}
                {{ user.uid + '&emsp;'}}
                <i v-if="user.isAdmin" class="ms-4 bi bi-person-fill">Admin</i>
              </span>
            </div>
            <div class="col-auto">
              <button type="button" class="btn-cancel" v-on:click="clickEdit(user)">
                <div class="d-none d-md-block">
                  <i class="bi bi-pencil"></i>
                  <span class="ps-1">{{ $t('labels.edit') }}</span>
                </div>
                <i class="bi bi-pencil d-block d-md-none"></i>
              </button>
              <button v-if="!user.isAdmin" type="button" class="btn-delete" v-on:click="deleteUser(user)">
                <div class="d-none d-md-block">
                  <i class="bi bi-trash"></i>
                  <span class="ps-1">{{ $t('labels.delete') }}</span>
                </div>
                <i class="bi bi-trash d-block d-md-none"></i>
              </button>
            </div>
          </div>
        </li>
      </template>
    </ul>
    <UserForm
      v-if="userFormMode !== ''"
      :user="userToEdit"
      :mode="userFormMode"
      v-on:add="addUser"
      v-on:edit="editUser"
      v-on:cancel="userFormMode = ''"
      ref="userform"
      id="userform"
      style="max-width: 650px"
    ></UserForm>
    
    <!-- <button v-if="userFormMode === ''" type="button" class="btn-powertrip" style="margin-left:0" v-on:click="userFormMode = 'add'; userToEdit = undefined">
      {{ $t('labels.addUser') }}
    </button>
    <input 
      type="file" 
      id="files" 
      style="display: none;"
      accept=".csv" 
      ref="file"
      v-on:change="handleFileUpload()"
    />
    <button @click="userImport" class="btn-powertrip" style="float:right"> Bulk Import </button> -->
  </div>
  <div v-else class="container">
    {{ this.isLoading }}
  </div>
</template>

<script>
import UserForm from '../Forms/UserForm.vue'
import axios from 'axios'
export default {
  name: 'UserList',
  components: { UserForm },
  props: ['role'],
  data() {
    return {
      isLoading: "Loading user information, please wait...",
      users: [],
      userToEdit: undefined,
      userFormMode: ''
    }
  },
  methods: {
    userImport() {
      document.getElementById('files').click(); 
    },
    async handleFileUpload() {
      this.file = this.$refs.file.files[0];
      
      const reader = new FileReader();
      reader.readAsText(this.file);
      
      reader.onload = () => {
        let lines = reader.result.split(/[\r\n]+/g)
        let headers = lines[0].split(',')
        headers.forEach(header => header.trim())

        let desiredHeaders = ['firstName', 'lastName', 'admin', 'password', 'email']
        
        for (let i = 0; i < headers.length; i++) {
          if (!desiredHeaders.includes(headers[i])) {
            alert ("Please ensure you have imported a CSV with a header line including firstName, lastName, email, password, admin")
            return
          }
        }

        let lineData = []
        for (let i = 1; i < lines.length; i++) {
          let currentLine = lines[i].split(',')
          currentLine.forEach(item => item.trim())
          if (currentLine.length >= 4) lineData.push(currentLine)
        }

        for (let i = 0; i < lineData.length; i++) {
          let currentUser = {
            firstName: lineData[i][headers.indexOf('firstName')],
            lastName: lineData[i][headers.indexOf('lastName')],
            isAdmin: lineData[i][headers.indexOf('admin')].toLowerCase() === "true",
            tempPassword: lineData[i][headers.indexOf('password')],
            mail: lineData[i][headers.indexOf('email')],
          }
          this.addUser(currentUser)
        }
        console.log("complete!")
      };

      reader.onerror = () => {
        console.log(reader.error);
      };
    },
    clickEdit(user){
      this.userFormMode = 'edit'
      this.userToEdit = user
    },
    async getUsers() {
      try {
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/admin/allUsers', {
          withCredentials: true,
        })
        if (res.status === 200) {
          if (res.data.users.length) {
            this.users = res.data.users.sort((a,b) => Number(b.adminRoles.includes(this.role)) - Number(a.adminRoles.includes(this.role))) //to sort list with admins at the top.
            for (let i = 0; i < this.users.length; i++) {
              this.users[i].isAdmin = (this.users[i].adminRoles.includes(this.role)) ? true : false
            }
          } else {
            this.users = []
          }
        }
        this.isLoading = "";
      } catch (error) {
        console.log(error)
        this.isLoading = "There was an error getting the user data."
      }
    },
    async addUser(user) {
      user['role'] = this.role
      try {
        const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/userKeycloak',
        user,
        {
          withCredentials: true,
        })
        if (res.status === 200) {
          this.getUsers()
          if (this.$refs.userform) this.$refs.userform.clear()
          this.userFormMode = ''
        }
      } catch (error) {
        console.log(error)
      }
    },
    async deleteUser(user) {
      if (confirm("Are you sure you would like to delete this user from your fleet?")) {
        try {
          const res = await axios.delete(process.env.VUE_APP_BACKEND_URL + '/api/admin/userKeycloak',
          {
            params: { uid: user.uid, role: this.role },
            withCredentials: true,
          })
          if (res.status === 200) {
            this.getUsers()
            if (this.$refs.userform) this.$refs.userform.clear()
            this.userFormMode = ''
          }
        } catch (error) {
          if (error.response){
            if (error.response.status === 401) {
              this.$router.push('login')
            } else {
              if(error.response){
                console.log(error.response.data)
              }
            }
          }
        }
      }
      
    },
    async editUser(user) {
      console.log('edit user')
      user['role'] = this.role
      try {
        const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/user/change',
        user,
        {withCredentials: true,}
        )
        if (res.status === 200) {
          this.getUsers()
          this.userFormMode = ''
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            this.$router.push('login')
          } else {
            console.log(error.response.data)
          }
        }
      }
    },
    
  },
  beforeMount() {
    this.getUsers();
  },
  watch: {
    role: function() {
      this.userFormMode = ''
      this.isLoading = "Loading user information, please wait..."
      this.getUsers()
    }
  }
}
</script>

<style>
</style>