<template>
  <form class="container" @submit.prevent="this.mode === 'add' ? this.$emit('add', this.formResource) : this.$emit('edit', this.formResource)">
    <div class="mb-2">
      <div class="row">
        <div class="col-sm">
          <label for="resourceFormMake" class="form-label">
            {{ $t('labels.make') }}
          </label>
          <input
            type="text"
            class="form-control"
            id="resourceFormMake"
            :placeholder="$t('comp.vehicle.exampleMake')"
            v-model="formResource.make"
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormModel" class="form-label">
            {{ $t('labels.model') }}
          </label>
          <input
            type="text"
            class="form-control"
            id="resourceFormModel"
            :placeholder="$t('comp.vehicle.exampleModel')"
            v-model="formResource.model"
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormYear" class="form-label">
            {{ $t('labels.year') }}
          </label>
          <input
            type="text"
            class="form-control"
            id="resourceFormYear"
            :placeholder="$t('comp.vehicle.exampleYear')"
            v-model="formResource.year"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label for="resourceFormLicense" class="form-label">
            {{ $t('labels.licensePlate') }}
          </label>
          <input
            type="text"
            class="form-control"
            id="resourceFormLicense"
            :placeholder="$t('comp.vehicle.exampleLicense')"
            v-model="formResource.licensePlate"
            required
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormOdometer" class="form-label">
            {{ $t('labels.odometer') }}
          </label>
          <input
            type="text"
            class="form-control"
            id="resourceFormOdometer"
            :placeholder="$t('comp.vehicle.exampleOdometer')"
            v-model="formResource.odometer"
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormCharge" class="form-label">
            {{ $t('labels.charge') }}
          </label>
          <input
            type="text"
            class="form-control"
            id="resourceFormCharge"
            :placeholder="$t('comp.vehicle.exampleCharge')"
            v-model="formResource.charge"
          />
        </div>
      </div>
      
      <div class="row">
        <!-- TODO:update this to be multiselectable dropdown -->
        <div class="col-sm">
          <label for="resourceFormGroup" class="form-label">
            {{ $t('labels.group') }}
          </label>
          <select class="form-select form-check-inline" id="resourceGroup" v-model="formResource.group[0]">
            <option v-for="role of this.roles" :key="role">{{ role }}</option>
          </select>
        </div>
        <div class="col-sm">
          <label for="resourceFormFuel" class="form-label">
            {{ $t('labels.fuel') }}
          </label>
          <select class="form-select form-check-inline" id="filterGroups" v-model="formResource.fuel">
            <option v-for="fuel of this.fuels" :key="fuel.name">{{ fuel.name }}</option>
          </select>
        </div>
        <div class="col-sm">
          <label for="resourceFormSeats" class="form-label">
            {{ $t('labels.seats') }}
          </label>
          <input
            type="text"
            class="form-control"
            id="resourceFormSeats"
            :placeholder="$t('comp.vehicle.exampleSeats')"
            v-model="formResource.seats"
          />
        </div>
        <div class="col-sm">
          <label for="resourceFormGeotabID" class="form-label">
            {{ $t('labels.geotabID') }}
          </label>
          <input
            type="text"
            class="form-control"
            id="resourceFormGeotabID"
            :placeholder="$t('comp.vehicle.exampleGeotabID')"
            v-model="formResource.geotabID"
          />
        </div>
        <div class="col-auto">
          <label for="resourceFormColor" class="form-label">
            {{ $t('labels.color') }}
          </label>
          <input
            style="min-height: 27px"
            type="color"
            class="form-control"
            id="resourceFormColor"
            v-model="formResource.color"
            @change="formResource.color = correctColorLuminance(formResource.color)"
          />
        </div>
      </div>
    </div>
    <div class="mb-2">
      <div class="row">
        <div class="col-sm">
          <label for="resourceFormDes" class="form-label">
            {{ $t('labels.description') }}
          </label>
          <textarea class="form-control" id="resourceFormDes" rows="3" v-model="formResource.description"></textarea>
        </div>
        <div class="col-sm">
          <div class="mb-2">
            <label for="resourceFormImg" class="form-label"> {{ $t('labels.image') }} (max 1MB)</label>
            <input class="form-control" type="file" id="resourceFormImg" @change="changeFile" accept="image/*" />
          </div>
          <div class = "row">
            <label v-if="this.resource.history !== ''" for="resourceFormHistory" class="form-label">Last Update: {{ this.resource.history }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-2">
      <template v-if="formResource.isDividable">
        <label for="resourceFormSubresourceAdd" class="form-label">
          {{ $t('labels.subresources') }}
        </label>
        <div class="input-group mb-1" v-if="this.mode !== 'edit'">
          <input type="text" class="form-control" id="resourceFormSubresourceAdd" :placeholder="$t('labels.addSubresource')" v-model="subresourceAdd" />
          <button v-on:click="addSubresource(this.subresourceAdd)" type="button" class="btn btn-outline-secondary">
            <i class="bi bi-plus"></i>
          </button>
        </div>
        <br v-if="this.mode === 'edit'" />
        <div v-for="subresource of formResource.subresources" :key="subresource" class="badge bg-secondary me-2 mb-1">
          {{ subresource }}
          <button v-on:click="deleteSubresource(subresource)" type="button" class="btn text-light p-0" v-if="this.mode !== 'edit'">
            <i class="bi bi-x"></i>
          </button>
        </div>
      </template>
    </div>
    <div class="mb-2">
      <button type="submit" class="btn-powertrip" v-if="this.mode === 'add'">
        {{ $t('labels.addResource') }}
      </button>
      <button type="submit" class="btn-powertrip" v-if="this.mode === 'edit'">
        {{ $t('labels.save') }}
      </button>
      <button type="button" class="btn-cancel" v-on:click="this.$emit('cancel')">
        {{ $t('labels.cancel') }}
      </button>
      <button type="button" class="btn-delete" v-if="this.mode === 'edit'" v-on:click="this.$emit('delete', resource.name)">
        <div class=" d-none d-md-block">
          <i class="bi bi-trash"></i>
          <span class="ps-1">{{$t('labels.delete')}}</span>
        </div>
        <i class="bi bi-trash d-block d-md-none"></i>
      </button>
    </div>
  </form>
</template>

<script>
export default {
  components: {},
  name: 'ResourceForm',
  props: {
    resource: {
      type: Object,
      default: function () {
        return {
          make: '',
          model: '',
          year: '',
          name: '',
          odometer: '',
          charge: '',
          group: [''],
          fuel: '',
          seats: '',
          geotabID: '',
          geotabName: '',
          licensePlate: '',
          permissionLevel: '',
          description: '',
          history: '',
          chargeHistory: '',
          img: undefined,
          color: undefined,
        }
      },
    },
    mode: {
      type: String,
      required: true,
      validator: function (value) {
        return ['add', 'edit', 'view'].indexOf(value) !== -1
      },
    },
  },
  data() {
    return {
      formResource: this.resource,
      subresourceAdd: '',
      groups: [],
      roles:[],
      fuels: [
        { name: 'EV/Hybrid' },
        { name: 'Petrol' }
      ],
    }
  },
  methods: {
    addSubresource(subresource) {
      const index = this.formResource.subresources.indexOf(subresource)
      if (index === -1 && subresource.length > 0) {
        this.formResource.subresources.push(subresource)
        this.subresourceAdd = ''
      }
    },
    deleteSubresource(subresource) {
      const index = this.formResource.subresources.indexOf(subresource)
      if (index !== -1) {
        this.formResource.subresources.splice(index, 1)
      }
    },
    clear() {
      this.formResource = {
        make: '',
        model: '',
        year: '',
        name: '',
        odometer: '',
        charge: '',
        group: [''],
        fuel: '',
        seats: '',
        geotabID: '',
        geotabName: '',
        permissionLevel: '',
        description: '',
        history: '',
        chargeHistory:'',
        img: undefined,
        color: this.correctColorLuminance(this.generateRandomColorHex()),
      }
    },
    changeFile(booking) {
      const reader = new FileReader()
      if (booking.target.files.length === 1 && booking.target.files[0].size < 1000000) {
        reader.readAsDataURL(booking.target.files[0])
        reader.onload = async () => {
          this.formResource.img = await this.resizedataURL(reader.result, 50, 50)
        }
      } else {
        this.formResource.img = undefined
      }
    },

    correctColorLuminance(hex) {
      if (hex) {
        // HEX to RGB
        var rgb = this.hexToRgb(hex)
        var luminance = this.calcRelativeLuminance(rgb)
        // setting 0.4 as border
        while (luminance > 0.4) {
          rgb = [rgb[0] >= 2 ? rgb[0] - 2 : 0, rgb[1] >= 7 ? rgb[1] - 7 : 0, rgb[2] >= 1 ? rgb[2] - 1 : 0]
          luminance = this.calcRelativeLuminance(rgb)
        }
        return this.rgbToHex(rgb[0], rgb[1], rgb[2])
      }
    },

    generateRandomColorHex() {
      const rgb = [255, 255, 255].map(function (v) {
        return Math.round(Math.random() * v)
      })
      return this.rgbToHex(rgb[0], rgb[1], rgb[2])
    },

    // From https://stackoverflow.com/a/52983833/13582326
    resizedataURL(datas, wantedWidth, wantedHeight) {
      return new Promise((resolve) => {
        // We create an image to receive the Data URI
        var img = document.createElement('img')

        // When the booking "onload" is triggered we can resize the image.
        img.onload = function () {
          // We create a canvas and get its context.
          var canvas = document.createElement('canvas')
          var ctx = canvas.getContext('2d')

          // We set the dimensions at the wanted size.
          canvas.width = wantedWidth
          canvas.height = wantedHeight

          // We resize the image with the canvas method drawImage();
          ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight)

          var dataURI = canvas.toDataURL()

          // This is the return of the Promise
          resolve(dataURI)
        }
        // We put the Data URI in the image's src attribute
        img.src = datas
      })
    },
    calcRelativeLuminance(rgb) {
      // CALC relative Luminance https://www.w3.org/TR/WCAG21/#dfn-relative-luminance
      const copy = rgb.map(function (v) {
        v /= 255
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
      })
      return copy[0] * 0.2126 + copy[1] * 0.7152 + copy[2] * 0.0722
    },

    rgbToHex(r, g, b) {
      return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
    },

    hexToRgb(hex) {
      const result = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/i.exec(hex)
      return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    },
  },
  beforeMount() {
    this.roles = this.$root.$root.getRoles()
    if (this.formResource.color === undefined) {
      this.formResource.color = this.correctColorLuminance(this.generateRandomColorHex())
    }
  },
  watch: {
    resource: function () {
      this.formResource = this.resource
    },
  },
}
</script>

<style>
</style>