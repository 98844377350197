export default {
  "resource": {
    "one": {
      "upper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressource"])},
      "lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ressource"])}
    },
    "more": {
      "upper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressourcen"])},
      "lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ressourcen"])}
    },
    "compound": {
      "upper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressourcen"])},
      "lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["📦"])}
  },
  "headlines": {
    "resourceBooking": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("resource.compound.upper"), "verwaltung"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überblick"])},
    "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "resources": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("resource.more.upper")])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
  },
  "labels": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Addresse"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])},
    "bookedUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebucht bis"])},
    "bookedFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebucht ab"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])},
    "partlyAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise verfügbar"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verfügbar"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen"])},
    "addResource": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("resource.one.upper"), " hinzufügen"])},
    "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisator"])},
    "resource": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("resource.one.upper")])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "uid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
    "isDividable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterteilbar"])},
    "subresources": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Unter", _linked("resource.more.lower")])},
    "addSubresource": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Unter", _linked("resource.one.lower"), " hinzufügen"])},
    "selectSubresources": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Unter", _linked("resource.more.lower"), " wählen"])},
    "onlyShowAvailableResources": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Nur verfügbare ", _linked("resource.more.upper"), " anzeigen"])},
    "separator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separator"])},
    "arraySeparator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Array Separator"])},
    "csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])}
  },
  "alerts": {
    "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung fehlgeschlagen!"])},
    "nameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name darf dies nicht enthalten: ^<>/\\\":?  oder 'service' sein."])},
    "bookingconflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gewählten Details verursachen einen Konflikt mit einer bereits bestehenden Buchung."])},
    "noResource": {
      "heading": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Keine ", _linked("resource.more.upper")])},
      "text": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Es ist kein ", _linked("resource.one.upper"), " zu sehen. Bitte fügen Sie unter ", _linked("headlines.settings"), " einen ", _linked("resource.one.upper"), " hinzu."])}
    },
    "request": {
      "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht autorisiert!"])}
    },
    "db": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich mit der Datenbank verbunden."])}
    }
  },
  "comp": {
    "login": {
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Anmelden"])}
    },
    "calendar": {
      
    },
    "overview": {
      
    },
    "booking": {
      "exampleSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. Max Mustermann für ein Gespräch"])},
      "resourcesBooked": (ctx) => {const { normalize: _normalize, linked: _linked, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ein ", _linked("resource.one.upper"), " ist bereits belegt."]), _normalize([_interpolate(_named("count")), " ", _linked("resource.more.upper"), " sind bereits belegt."])])},
      "allResourcesBooked": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["In diesem Zeitraum sind alle ", _linked("resource.more.upper"), " belegt."])},
      "bookingSuccess": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Buchung war erfolgreich!"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du hast ", _interpolate(_named("resources")), " vom ", _interpolate(_named("startDate")), " bis zum ", _interpolate(_named("endDate")), " gebucht."])}
      },
      "bookingFailure": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen.."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Buchung war nicht erfolgreich, probier es nochmal."])}
      }
    },
    "settings": {
      
    },
    "import": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importiere mehrere Buchungen im CSV Format."])}
    },
    "export": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportiere alle Buchungen ins CSV Format."])}
    },
    "resource": {
      "exampleName": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["z.B. ", _linked("resource.one.upper"), " 103"])}
    }
  },
  "mail": {
    "salutation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hallo ", _interpolate(_named("recipient"))])},
    "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siehe deine Buchungungen unter"])},
    "confirmation": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["✅ Buchungsbestätigung"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Buchung war erfolgreich."])}
    },
    "deletion": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["❌ Buchungslöschung"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Buchung wurde gelöscht."])}
    },
    "change": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🔄 Buchungsänderung"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Buchung wurde geändert:"])}
    },
    "reminder": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["❕ Erinnerung"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden Räume sind demnächst dran:"])},
      "bookedFrom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gebucht von ", _interpolate(_named("organizer"))])}
    },
    "notifyOnEnd": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ℹ Aufenthaltsende"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute enden folgende Buchungen:"])}
    }
  }
}